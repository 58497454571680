import React from "react"
import { PageProps, graphql } from "gatsby"
import Helmet from "react-helmet"

import { SEO } from "src/components/seo"
import { VideoLoopResponsive } from "src/components/video-responsive"
import { ImgFluid } from "src/components/img-fluid"
import { VideoPlayer } from "src/components/video-player"

import {
  HeadlineAndParagraph,
  HeaderHero,
  CaseStudyCapabilities,
} from "src/components/layouts"

import {
  Container,
  Block,
  Grid,
  Col,
  HorizontalRuler,
  Headline,
  Paragraph,
  HeadlineHero,
} from "src/components/primitives"

import { useLightTheme } from "src/context/theme"

type DataProps = {
  project: {
    seo: {
      title: string
      description: string
      image: {
        publicURL: string
      }
    }
    copy: {
      title: string
      subtitle: string
      intro: string
      concepts: string
      vision_title: string
      vision_copy: string
      gallery_title: string
      gallery_copy: string
      wall_copy_1: string
      wall_copy_2: string
      expertise: string
    }
    images: {
      hero: any
      grid_2_1: any
      grid_2_2: any
      grid_2_3: any
      grid_2_4: any
      grid_4_1: any
      grid_4_2: any
      grid_4_3: any
      grid_4_4: any
      foh_full: any
      led_wall: any
    }
    videos: {
      hero: any
      grid_4_3: any
      grid_4_4: any
      foh_full: any
      led_wall: any
    }
  }
}

const CaseStudy: React.FC<PageProps<DataProps>> = ({ data }) => {
  const { seo, copy, images, videos } = data.project

  useLightTheme()

  return (
    <article aria-label={copy.title}>
      <Helmet
        bodyAttributes={{
          class: "theme-light",
        }}
      />
      <SEO
        title={seo.title}
        description={seo.description}
        image={seo.image.publicURL}
      />
      <Container>
        <HeaderHero>
          <HeadlineHero>
            {copy.title}
            <span>{copy.subtitle}</span>
          </HeadlineHero>
        </HeaderHero>
      </Container>

      <Container bleed>
        <Block>
          <VideoLoopResponsive 
            video={videos.hero} 
            image={images.hero} 
          />
        </Block>
      </Container>

      <Container>
        <Paragraph>{copy.intro}</Paragraph>
      </Container>

      <Container>
        <HorizontalRuler />
      </Container>

      <Container>
        <Block>
          <Grid gap columns={{ bp0: "$1", bp1: "$2" }}>
            <ImgFluid image={images.grid_2_1} />
            <ImgFluid image={images.grid_2_2} />
            <ImgFluid image={images.grid_2_3} />
            <ImgFluid image={images.grid_2_4} />
          </Grid>
        </Block>
      </Container>

      <Container>
        <Paragraph>{copy.concepts}</Paragraph>
      </Container>

      <Container>
        <HorizontalRuler />
      </Container>

      <Container>
        <HeadlineAndParagraph>
          <Headline>{copy.vision_title}</Headline>
          <Paragraph>{copy.vision_copy}</Paragraph>
        </HeadlineAndParagraph>
      </Container>

      <Container bleed>
        <Block>
          <VideoPlayer
            video={videos.foh_full}
            poster={images.foh_full}
            ratio="$16/9"
          />
        </Block>
      </Container>

      <Container>
        <HorizontalRuler />
      </Container>

      <Container>
        <HeadlineAndParagraph>
          <Headline>{copy.gallery_title}</Headline>
          <Paragraph>{copy.gallery_copy}</Paragraph>
        </HeadlineAndParagraph>
      </Container>

      <Container>
        <Block>
          <Grid gap columns={{ bp0: "$1", bp1: "$2" }}>
            <Col
              start={{ bp0: "$1", bp1: "$1" }}
              end={{ bp0: "$2", bp1: "$3" }}
            >
              <ImgFluid image={images.grid_4_1} />
            </Col>
            <Col>
              <ImgFluid image={images.grid_4_2} />
            </Col>
            <Col>
              <VideoLoopResponsive
                video={videos.grid_4_3}
                image={images.grid_4_3}
              />
            </Col>
            <Col
              start={{ bp0: "$1", bp1: "$1" }}
              end={{ bp0: "$2", bp1: "$3" }}
            >
              <VideoLoopResponsive
                video={videos.grid_4_4}
                image={images.grid_4_4}
              />
            </Col>
          </Grid>
        </Block>
      </Container>

      <Container>
        <Grid gap columns={{ bp4: "$12" }}>
          <Col start={{ bp4: "$6" }} end={{ bp4: "$13" }}>
            <Paragraph>{copy.wall_copy_1}</Paragraph>
          </Col>
        </Grid>
      </Container>

      <Container bleed>
        <Block>
          <VideoLoopResponsive
            ratio="$16/9"
            video={videos.led_wall}
            image={images.led_wall}
          />
        </Block>
      </Container>

      <Container>
        <Paragraph>{copy.wall_copy_2}</Paragraph>
      </Container>

      <Container>
        <CaseStudyCapabilities>{copy.expertise}</CaseStudyCapabilities>
      </Container>
    </article>
  )
}

export default CaseStudy

export const pageQuery = graphql`
  query {
    project: workJson(id: { eq: "hilton" }) {
      id
      seo {
        title
        description
        image {
          publicURL
        }
      }
      copy {
        title
        subtitle
        intro
        concepts
        vision_title
        vision_copy
        gallery_title
        gallery_copy
        wall_copy_1
        wall_copy_2
        expertise
      }
      images {
        hero {
          alt
          src {
            childImageSharp {
              ...HeroImageFragment
            }
          }
        }
        grid_2_1 {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_2_2 {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_2_3 {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_2_4 {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_4_1 {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }

        grid_4_2 {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_4_3 {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_4_4 {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        foh_full {
          alt
          src {
            childImageSharp {
              ...HeroImageFragment
            }
          }
        }
        led_wall {
          alt
          src {
            childImageSharp {
              ...HeroImageFragment
            }
          }
        }
      }
      videos {
        hero {
          src {
            src_large
            src_medium
            src_small
          }
        }
        grid_4_3 {
          src
        }
        grid_4_4 {
          src {
            src_large
            src_medium
            src_small
          }
        }
        foh_full {
          src {
            src_large
            src_medium
            src_small
          }
        }
        led_wall {
          src {
            src_large
            src_medium
            src_small
          }
        }
      }
    }
  }
`
